<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space bg-white halfRounded overflow-hiddon">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-xxl-8 text-center">
                        <h1 class="bold mb-md-3">{{$t('about.text191')}} <span class="secondary">{{$t('about.text55')}}</span></h1>
                        <p>{{$t('about.text192')}}</p>
                        <div class="dualButton text-center mt-4">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text193')}}</router-link>
                            <a v-else :href="'/register/'" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text193')}}</a>
                        </div>
                        <div class="zuluGuard mt-md-5">
                            <img src="/assets/images/become-trader-hero-banner.png" :alt="$t('about.text341`')" :title="$t('about.text342')" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white pt-0 space">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-xxl-8 text-center">
                        <div class="boxed">
                            <h2 class="mb-md-4">{{$t('about.text194')}}</h2>
                            <p>{{$t('about.text195')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space customCarousel">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-9">
                        <div class="mb-4 mb-md-5 text-center">
                            <h2>{{$t('about.text196')}}</h2>
                        </div>
                        <div id="carouselExampleIndicators" class="carousel slide row align-items-center traderWork">
                            <div class="col-12 col-sm-12 col-md-2">
                                <div class="carousel-indicators">
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></span>
                                        <span class="visually-hidden"></span>
                                    </button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">{{$t('about.text197')}}</button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2">{{$t('about.text198')}}</button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3">{{$t('about.text199')}}</button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-long-arrow-down" aria-hidden="true"></i></span>
                                        <span class="visually-hidden"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-10">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <div class="d-block d-md-flex text-center text-md-start align-items-center mt-4 mt-md-0">
                                            <div class="content me-3">
                                                <h5 class="medium">{{$t('about.text200')}}</h5>
                                                <p>{{$t('about.text201')}}</p>
                                            </div>
                                            <v-lazy-image src="/assets/images/Oversee.png" class="d-block w-100" :alt="$t('about.text343')" :title="$t('about.text344')" />
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="d-block d-md-flex text-center text-md-start align-items-center mt-4 mt-md-0">
                                            <div class="content me-3">
                                                <h5 class="medium">{{$t('about.text202')}}</h5>
                                                <p>{{$t('about.text203')}}</p>
                                            </div>
                                            <v-lazy-image src="/assets/images/Interact.png" class="d-block w-100" :alt="$t('about.text345')" :title="$t('about.text346')" />
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="d-block d-md-flex text-center text-md-start align-items-center mt-4 mt-md-0">
                                            <div class="content me-3">
                                                <h5 class="medium">{{$t('about.text204')}}</h5>
                                                <p>{{$t('about.text205')}}</p>
                                            </div>
                                            <v-lazy-image src="/assets/images/broadcast.png" class="d-block w-100" :alt="$t('about.text347')" :title="$t('about.text348')" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dualButton text-center mt-4 mt-md-5">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text193')}}</router-link>
                            <a v-else :href="'/register/'" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text193')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 mb-4 mb-md-4 text-center">
                        <h2>{{$t('about.text206')}}</h2>
                        <p>{{$t('about.text207')}}</p>
                    </div>
                    <div class="col-12 col-md-10 mb-4">
                        <div class="responsive-table wl-table compensationSchemes">
                            <table class="table table-bordered cellSpacing mb-0">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <!-- <th>
                                            <span class="f-12 d-block">Live Investors on the</span>
                                            <span class="f-16">Profit Sharing Plan</span>
                                        </th> -->
                                        <th>
                                            <span class="f-12 d-block">{{$t('about.text208')}}</span>
                                            <span class="f-16">{{$t('about.text209')}}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h6>{{$t('about.text210')}}</h6>
                                        </td>
                                        <!-- <td>
                                            <p class="border-bottom pb-3">20% Performance Fee, every time Traders generate a profitable monthly PnL* above High Water Mark**</p>
                                            <p class="f-12"><i> *Affiliate receives 20% of the profit made by ZuluTrade as well as 20% of the monthly fee applied by ZuluTrade.</i></p>
                                            <p class="f-12">
                                                <i>
                                                    ** High Water Mark (HWM) is the highest peak in value that a Trader produces on an investor’s trading account during any given month from the moment the investor starts following him/her.
                                                </i>
                                            </p>
                                        </td> -->
                                        <td style="vertical-align: top;">
                                            <p class="border-bottom pb-3">{{$t('about.text211')}}</p>
                                            <p class="f-12"><i>{{$t('about.text212')}}</i></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 col-md-9 text-center">
                        <p class="f-12">
                            <i><b>{{$t('about.text213')}}</b>: {{$t('about.text214')}}</i>
                        </p>
                    </div>
                    <div class="col-12">
                        <div class="text-center mt-4">
                            <!-- <router-link to="/affiliate-guide" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text215')}}</router-link> -->
                            <router-link to="/leader-guide" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text216')}}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space automated4">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 mb-md-4 pb-4 text-center">
                        <h2 class="">{{$t('about.text217')}}</h2>
                        <p>
                            {{$t('about.text218')}} <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="secondary" target="_blank">{{$t('about.text219')}}</router-link><a v-else class="secondary" :href="'/register/'" target="_blank">{{$t('about.text219')}}</a>. {{$t('about.text220')}}
                        </p>
                    </div>
                    <div class="col-12">
                        <ul class="listCounter affiliateStep">
                            <li class="d-flex align-items-start pb-4">
                                <span class="customXpad d-flex justify-content-center mb-3 mb-sm-0">
                                    <v-lazy-image class="d-block" src="/assets/images/open-account.svg" :alt="$t('about.text349')" :title="$t('about.text350')" />
                                </span>
                                <label class="d-block">
                                    <h4 class="mb-2 bold">{{$t('about.text221')}}</h4>
                                    <p>{{$t('about.text222')}}</p>
                                </label>
                            </li>
                            <li class="d-flex align-items-start pb-4">
                                <span class="customXpad d-flex justify-content-center mb-3 mb-sm-0">
                                    <v-lazy-image class="d-block" src="/assets/images/Start-journey.svg" :alt="$t('about.text351')" :title="$t('about.text352')" />
                                </span>
                                <label class="d-block">
                                    <h4 class="mb-2 bold">{{$t('about.text223')}}</h4>
                                    <p>{{$t('about.text224')}}</p>
                                </label>
                            </li>
                            <li class="d-flex align-items-start pb-4">
                                <span class="customXpad d-flex justify-content-center mb-3 mb-sm-0">
                                    <v-lazy-image class="d-block" src="/assets/images/step-6.svg" :alt="$t('about.text353')" :title="$t('about.text354')" />
                                </span>
                                <label class="d-block">
                                    <h4 class="mb-2 bold">{{$t('about.text225')}}</h4>
                                    <p>{{$t('about.text226')}}</p>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <div class="text-center mt-4">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text193')}}</router-link>
                            <a v-else :href="'/register/'" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text193')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white automated5 fiveColumn overflow-hidden">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center mb-md-4 pb-4">
                        <h2>{{$t('about.text227')}}</h2>
                        <p>{{$t('about.text228')}}</p>
                    </div>
                </div>
                <div class="row" v-show="store.topTrdaersList[3].result && store.topTrdaersList[3].result.length > 0">
                    <div class="col col1 trader-col" v-for="item, index in  store.topTrdaersList[3].result.slice(0,5)" :key="index">
                        <div class="card h-100" v-show="item.trader && Object.keys(item.trader).length">
                            <div class="card-body px-0 pb-0" v-show="item.trader.profile && Object.keys(item.trader.profile).length > 0">
                                <div class="text-center position-relative" v-show="item.trader.profile.name">
                                    <!-- <span class="top-avtar" v-if="item.trader.profile.countryName">
                                <v-lazy-image width="100" :src="'/assets/images/icon/zuluicons/Flags/'+item.trader.profile.countryIsoCode.toLowerCase()+'.png'" :alt="item.trader.profile.countryName" :title="item.trader.profile.countryName">
                                </span> -->
                                    <div class="vueRisk px-3 d-flex align-items-start justify-content-between">
                                        <p class="bTn mb-0 f-12 bold uppercase" :class="[{'blueBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==1},{'greenBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==2},{'yellowBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==3},{'redBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==4},{'pinkBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==5}]" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">
                                            Risk {{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk}}
                                        </p>
                                        <!--yellowBtn, dashgreen, blueBtn-->
                                         <a :href="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                            <span class="circleImg d-inline-block mb-3">
                                                <v-lazy-image
                                                    :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'"
                                                    :alt="item.trader.profile.name"
                                                    :title="item.trader.profile.name"
                                                />
                                            </span>
                                        </a>
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:void(0)" class="me-1" @click="AddWatchList(item)"  :class="{'disabled' : store.customerDetail?.readOnly}"><vue-feather type="star" size="19" :class="[{'filled' : isFollowed(item.trader.providerId)}]"></vue-feather></a>
                                            <div class="position-relative">
                                                <a href="javascript:void(0)" class="iconButton ms-1" @click="(opendots != item.trader.profile.zuluAccountId) ? opendots = item.trader.profile.zuluAccountId : opendots = -1">
                                                    <i class="gray fa fa-ellipsis-v" aria-hidden="true"></i>
                                                </a>
                                                <ul class="dropdown-menu" data-popper-placement="bottom-start" :class="[{ 'show': opendots ==  item.trader.profile.zuluAccountId }]">
                                                    <li><a class="f-12 dropdown-item" href="javascript:void(0);" @click="sharePop = item.trader.providerId">{{$t('about.text229')}}</a></li>
                                                    <li><router-link :to="{path:'/compare',query:{p:item.trader.profile.id}}" class="f-12 dropdown-item">{{$t('about.text230')}}</router-link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <a :href="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                    <div class="title">
                                        <a href="javascript:void(0)">
                                            <h6 class="mb-1 f-16">{{ item.trader.profile.name }}</h6>
                                        </a>
                                            <div class="element d-flex align-items-center justify-content-center mb-3 flex-wrap">
                                                <div class="element d-flex align-items-center justify-content-center me-2" v-if="item.trader.overallStats.followers">
                                                    <p class="moregray f-12 mb-0 me-1">{{$t('about.text231')}}</p>
                                                    <p class="f-12 medium mb-0">{{ item.trader.overallStats.followers || 0}}</p>
                                                </div>
                                                <div class="element d-flex align-items-center justify-content-center" v-if="item.trader.overallStats && item.trader.overallStats.includedInWatchlist">
                                                    <p class="moregray f-12 mb-0 me-1">{{$t('about.text232')}}</p>
                                                    <p class="f-12 medium mb-0">{{ item.trader.overallStats.includedInWatchlist || 0 }}</p>
                                                </div>
                                            </div>
                                            <div class="element">
                                                <!-- <p class="f-12 mb-0">ROI</p> -->
                                                <p class="mb-0 px-1 bold d-inline-flex" :class="ROIClass(item)" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">
                                                    {{parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0 ? '+' : '-'}}{{
                                                    parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(1) || 0 }}%
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="chartElement" :id="'topTraders' + item.trader.profile.zuluAccountId + '_' + store.topTrdaersList[3].order" style="width: 100%; height: 126px; display: flex;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="dualButton d-flex align-items-center justify-content-center mt-4 mt-md-5">
                            <router-link to="/leaders" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('about.text233')}}</router-link>
                            <!-- <a href="javascript:void(0)" class="button zulu_btn rounded border-button">See all Combos</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space">
            <div class="container">
                <div class="row flex-row-reverse align-items-center mb-4 mb-lg-5">
                    <div class="col-12 mb-md-4 pb-4 text-center">
                        <h2 class="">{{$t('about.text234')}}</h2>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <v-lazy-image class="d-block mx-auto mix-blend-mode" src="/assets/images/mt4-Icon.png" :alt="$t('about.text355')" :title="$t('about.text356')" />
                    </div>
                    <div class="col-12 col-md-6 text-center text-md-start">
                        <h2 class="mb-2 mb-md-4">{{$t('about.text235')}}</h2>
                        <p>{{$t('about.text236')}}</p>
                        <p>{{$t('about.text237')}}</p>
                    </div>
                </div>
                <div class="row align-items-center mb-4 mb-lg-5">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <v-lazy-image class="d-block mix-blend-mode" src="/assets/images/mt5-Icon.png" :alt="$t('about.text357')" :title="$t('about.text358')" />
                    </div>
                    <div class="col-12 col-md-6 text-center text-md-start">
                        <h2 class="mb-2 mb-md-4">{{$t('about.text238')}}</h2>
                        <p>{{$t('about.text239')}}</p>
                    </div>
                </div>
                <div class="row flex-row-reverse mb-4 mb-md-5 align-items-center">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <v-lazy-image class="d-block mx-auto mix-blend-mode" src="/assets/images/xoh.png" :alt="$t('about.text355')" :title="$t('about.text356')" />
                    </div>
                    <div class="col-12 col-md-6 text-center text-md-start">
                        <h2 class="mb-2 mb-md-4">{{$t('about.text240')}}</h2>
                        <p>{{$t('about.text241')}}</p>
                    </div>
                </div>
                <div class="row align-items-center mb-4 mb-md-0">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <v-lazy-image width="400" class="d-block mix-blend-mode" src="/assets/images/ActTrader.png" :alt="$t('about.text359')" :title="$t('about.text360')" />
                    </div>
                    <div class="col-12 col-md-6 text-center text-md-start">
                        <h2 class="mb-2 mb-md-4">{{$t('about.text242')}}</h2>
                        <p>{{$t('about.text243')}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center mt-4 mt-md-5">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn px-md-5">{{$t('about.text193')}}</router-link>
                            <a v-else :href="'/register/'" class="button fillBtn zulu_btn px-md-5">{{$t('about.text193')}}</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-white automated5">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center mb-md-4 pb-4">{{$t('about.text244')}}</h2>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <div class="d-block d-sm-flex align-items-start affiliateReason">
                            <span class="automated me-2"><v-lazy-image class="d-block" src="/assets/images/no-hiden-fees.svg" :alt="$t('about.text361')" :title="$t('about.text362')" /></span>
                            <div class="contentCalc">
                                <h4 class="bold">{{$t('about.text245')}}</h4>
                                <p>{{$t('about.text246')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <div class="d-block d-sm-flex align-items-start affiliateReason">
                            <span class="automated me-2"><v-lazy-image class="d-block" src="/assets/images/Multiple-brokers.svg" :alt="$t('about.text363')" :title="$t('about.text364')" /></span>
                            <div class="contentCalc">
                                <h4 class="bold">{{$t('about.text247')}}</h4>
                                <p>{{$t('about.text248')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <div class="d-block d-sm-flex align-items-start affiliateReason">
                            <span class="automated me-2"><v-lazy-image class="d-block" src="/assets/images/verified-track-record.svg" :alt="$t('about.text365')" :title="$t('about.text366')" /></span>
                            <div class="contentCalc">
                                <h4 class="bold">{{$t('about.text249')}}</h4>
                                <p>{{$t('about.text250')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <div class="d-block d-sm-flex align-items-start affiliateReason">
                            <span class="automated me-2"><v-lazy-image class="d-block" src="/assets/images/traders-desk.svg" :alt="$t('about.text367')" :title="$t('about.text368')" /></span>
                            <div class="contentCalc">
                                <h4 class="bold">{{$t('about.text251')}}</h4>
                                <p>{{$t('about.text252')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="text-center mt-4">
                            <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn px-md-5">{{$t('about.text193')}}</router-link>
                            <a v-else :href="'/register/'" class="button fillBtn zulu_btn px-md-5">{{$t('about.text193')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space faqs automated6">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-md-4 pb-4">{{$t('about.text253')}}</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        {{$t('about.text254')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('about.text255')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        {{$t('about.text256')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('about.text257')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        {{$t('about.text258')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('about.text259')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        {{$t('about.text260')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        {{$t('about.text261')}}
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingfive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                        {{$t('about.text262')}}
                                    </button>
                                </h2>
                                <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('about.text263')}}
                                        <ul class="dotList mb-0 mt-2">
                                            <li>{{$t('about.text264')}}</li>
                                            <li>{{$t('about.text265')}}</li>
                                            <li>{{$t('about.text266')}}</li>
                                            <li>{{$t('about.text267')}}</li>
                                            <li>{{$t('about.text268')}}</li>
                                            <li>{{$t('about.text269')}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingsix">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                        {{$t('about.text270')}}
                                    </button>
                                </h2>
                                <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        {{$t('about.text271')}}
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingSeven">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                        {{$t('about.text272')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        {{$t('about.text273')}} <a class="secondary" :href="'/register/'" target="_blank">{{$t('about.text274')}}</a>.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingEight">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                        {{$t('about.text275')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('about.text276')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingNine">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                        {{$t('about.text277')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        {{$t('about.text278')}}
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTen">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                        {{$t('about.text279')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        {{$t('about.text280')}}
                                        <router-link to="/autoprotect-your-account" class="secondary" target="_blank">{{$t('about.text281')}}</router-link>.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingEleven">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                       {{$t('about.text282')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        {{$t('about.text283')}}
                                        <router-link to="/zuluranking" class="secondary" target="_blank">{{$t('about.text284')}}</router-link>.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingtwelve">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetwelve" aria-expanded="false" aria-controls="flush-collapsetwelve">
                                        {{$t('about.text285')}}
                                    </button>
                                </h2>
                                <div id="flush-collapsetwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingtwelve" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        {{$t('about.text286')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <sharePop :shareURL="sharePop" :close="()=>sharePop = ''" v-if="sharePop" :shareType="'leaderProfile'"></sharePop>
    <loginPopup v-if="showLoginPopup"></loginPopup>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
    import sharePop from "@/views/social-feed/share-pop";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                showLoginPopup: false,
                opendots: false,
                sharePop: '',
            };
        },
        components: {
            sharePop
        },
        methods: {
            isFollowed(id) {
                if (this.store.leaderWatchList.length) {
                    let data = this.store.leaderWatchList.map((i) => i.id);
                    if (data.includes(parseInt(id))) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            ROIClass(item) {
                if (parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0) {
                    return "greenView";
                } else {
                    return "redView";
                }
            },
            callWatchList() {
                this.store.getWatchList({}, false,"180");
            },
            AddWatchList(item) {
                if (!this.store.user.access_token) {
                    this.showLoginPopup = true;
                } else {
                    this.showLoginPopup = false;
                    if (this.isFollowed(item.trader.providerId)) {
                        this.addWatchList(item, "DELETE");
                    } else {
                        this.addWatchList(item, "ADD");
                    }
                }
            },
            addWatchList(item, type) {
                let json = {};
                if (type == "ADD") {
                    json["id"] = item.trader.providerId;
                    json["type"] = "LEADER";
                    json["name"] = item.trader.profile.name;
                    json["roi"] = 0;
                    json["chart"] = {};
                    let data = this.store.leaderWatchList;
                    data.push(json);
                    this.store.$patch({ leaderWatchList: data });
                } else if (type == "DELETE") {
                    let data = this.store.leaderWatchList.filter((i) => parseInt(i.id) != parseInt(item.trader.providerId));
                    this.store.$patch({ leaderWatchList: data });
                }
                let payload = {
                    item: item.trader.providerId,
                    type: "LEADER",
                };
                this.store.addToWatchList(payload, false, this, type);
            },
            getTopTradersList() {
                this.store.callTopTradersList({}, true).then(() => {
                    //console.log('==>', this.store.topTrdaersList[3])
                    if (this.store.topTrdaersList.length && Object.keys(this.store.topTrdaersList[3]).length) {
                        if (this.store.topTrdaersList[3].result && this.store.topTrdaersList[3].result.length > 0) {
                            this.store.topTrdaersList[3].result.slice(0, 5).forEach((item) => {
                                if (
                                    item.trader &&
                                    Object.keys(item.trader).length &&
                                    item.trader.profile &&
                                    Object.keys(item.trader.profile).length &&
                                    item.trader.profile.zuluAccountId &&
                                    item.trader.timeFrameGraph &&
                                    item.trader.timeFrameGraph.series &&
                                    item.trader.timeFrameGraph.series.length &&
                                    item.trader.timeFrameGraph.series[0].data &&
                                    item.trader.timeFrameGraph.series[0].data.length
                                ) {
                                    am5.ready(() => {
                                        this.tradersChart(item.trader.profile.zuluAccountId + "_" + this.store.topTrdaersList[3].order, item.trader.timeFrameGraph.series[0].data);
                                    });
                                }
                            });
                        }
                    }
                });
            },
            tradersChart(id, chartdata) {
                //console.log(chartdata[chartdata.length-1])
                let chartId = "topTraders" + id;
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === chartId);
                d?.dispose();
                let root = am5.Root.new(chartId);
                root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);

                root.dateFormatter.setAll({
                    dateFormat: "dd MMM yyyy",
                    dateFields: ["valueX"],
                });

                // Create chart
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                    })
                );

                // Create axes
                let xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        visible: false,
                        groupData: true,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                            minGridDistance: 10,
                        }),
                        // tooltip: am5.Tooltip.new(root, {})
                    })
                );

                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        visible: false,
                        strictMinMax: true,
                        baseValue : -1000,
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                xAxis.get("renderer").grid.template.set("forceHidden", true);
                yAxis.get("renderer").grid.template.set("forceHidden", true);
                // let yRenderer = yAxis.get("renderer");
                // yRenderer.grid.template.setAll({
                //     stroke: am5.color(0xFF0000),
                //     strokeWidth: 2
                // });

                //let range = yAxis.createAxisRange(rangeDataItem);

                chart.get("colors").set("colors", [am5.color(0xff7200)]);

                // Add series
                var series = chart.series.push(
                    am5xy.LineSeries.new(root, {
                        minBulletDistance: 10,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            // pointerOrientation: "horizontal",
                            labelText: "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}  \n [bold]ROI(%)[/] : {valueY}",
                        }),
                    })
                );

                series.fills.template.setAll({
                    visible: true,
                });

                series.fills.template.set(
                    "fillGradient",
                    am5.LinearGradient.new(root, {
                        stops: [
                            {
                                opacity: 0.1,
                            },
                            {
                                opacity: 0.04,
                            },
                        ],
                    })
                );

                series.strokes.template.setAll({
                    strokeWidth: 2,
                });

                // Set up data processor to parse string dates
                series.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "yyyy-MM-dd",
                    dateFields: ["y"],
                });

                // series.bullets.push(function() {
                //     let circle = am5.Circle.new(root, {
                //         radius: 4,
                //         // fill: root.interfaceColors.get("background"),
                //         // stroke: this.series.get("fill"),
                //         strokeWidth: 2
                //     })

                //     return am5.Bullet.new(root, {
                //         sprite: circle
                //     })
                // });
                // series.bullets.push(function(root) {
                //   return am5.Bullet.new(root, {
                //     sprite: am5.Circle.new(root, {
                //       radius: 4,
                //       fill: series.get("fill")
                //     })
                //   });
                // });
                // Add cursor
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        // xAxis: xAxis,
                        // behavior: "zoomX"
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);

                // chart.zoomOutButton.set("forceHidden", true);

                xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });

                // Set data
                var data = chartdata;
                series.data.setAll(data);

                // Make stuff animate on load
                series.appear(1000);
                chart.appear(1000, 100);
            },
        },
        created() {
            this.getTopTradersList();
        },
    };
</script>
